<template>
    <loader v-if="loading" :style="'box'"></loader>
    <div v-if="!loading">
    <div class="grid">
        <div class="col-12 xl:col-6">
            <div class="card height-100 flex flex-column">
                <div class="flex justify-content-center p-3 rounded-normal content-alt-bgcolor">
                    <!-- <img src="layout/images/widgets/feature-faq.svg" alt="feature-faq"> -->
                    <h2 class="m-0">Anlagenkonfiguration</h2>
                </div>
                <div class="formgrid mt-3">
                    <div class="field col">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Anlagen-ID</span>
                            <InputText v-model="setupClient.id" placeholder="ENATxxxx" />
                        </div>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Label</span>
                            <InputText v-model="setupSystem.label" placeholder="Beschreibung" />
                        </div>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Name</span>
                            <InputText v-model="setupClient.name" placeholder="Meister Gärtner" />
                        </div>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Anlagenbeschreibung</span>
                            <InputText v-model="setupClient.desc" placeholder="Gärtnerei auf der Had" />
                        </div>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Adresse</span>
                            <InputText v-model="setupLocation.address" placeholder="Mitterweg 999" />
                        </div>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">PLZ</span>
                            <InputNumber v-model="setupLocation.zip" placeholder="1110" :useGrouping="false" />
                        </div>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Stadt</span>
                            <InputText v-model="setupLocation.city" placeholder="Wien" />
                        </div>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Land</span>
                            <InputText v-model="setupLocation.country" placeholder="Österreich" />
                        </div>
                    </div>
                    <Divider />
                </div>
                <div class="flex justify-content-end align-items-center pt-3 mt-auto">
                    <small class="muted-text mr-1">Aktionen</small>
                    <Button class="mr-1" label="Refresh Status-IO" @click="refreshOpcUaStatusIo" disabled></Button>
                    <Button class="mr-1" label="Restart OPCUA-Service" @click="restartOpcUaService" disabled></Button>
                    <Button class="mr-1" label="Start OPCUA-Service" @click="startOpcUaService" disabled></Button>
                    <Button class="mr-1" label="Stop OPCUA-Service" @click="stopOpcUaService" disabled></Button>
                </div>
            </div>
        </div>
        <div class="col-12 xl:col-6">
            <div class="card height-100 flex flex-column">
                <div class="flex justify-content-center p-3 rounded-normal content-alt-bgcolor">
                    <!-- <img src="layout/images/widgets/feature-faq.svg" alt="feature-faq"> -->
                    <h2 class="m-0">App-Einstellungen</h2>
                </div>
                <div class="formgrid mt-3">
                    <div class="field col">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Standby-Timeout</span>
                            <InputNumber v-model="setupApp.standbyTimeout" placeholder="0 Sekunden" suffix=" Sekunden" :useGrouping="false" :min="10" />
                        </div>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Discord-Link</span>
                            <InputText v-model="setupApp.discordLink" placeholder="DISCORD" />
                        </div>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Youtube-Link</span>
                            <InputText v-model="setupApp.youtubeLink" placeholder="YOUTUBE" />
                        </div>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Dropbox-Link</span>
                            <InputText v-model="setupApp.dropboxLink" placeholder="DROPBOX" />
                        </div>
                    </div>
                    <Divider />
                </div>
                <div class="flex justify-content-between align-items-center pt-3 mt-auto">
                    <small class="muted-text">Speichern nicht vergessen!</small>
                </div>
            </div>
        </div>
        <div class="col-12 xl:col-6" v-if="isMaster">
            <div class="card height-100 flex flex-column">
                <div class="flex justify-content-center p-3 rounded-normal content-alt-bgcolor">
                    <!-- <img src="layout/images/widgets/feature-faq.svg" alt="feature-faq"> -->
                    <h2 class="m-0">Admin-Einstellungen</h2>
                </div>
                <div class="formgrid mt-3">
                    <div class="field col">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Copyright Year</span>
                            <InputText v-model="setupCopyright.copyrightYear" placeholder="2022" />
                        </div>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Copyright Txt</span>
                            <InputText v-model="setupCopyright.copyrightTxt" placeholder="Copyright Text" />
                        </div>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Copyright Link</span>
                            <InputText v-model="setupCopyright.copyrightLink" placeholder="Copyright Link" />
                        </div>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Version Info</span>
                            <InputText v-model="setupCopyright.versionInfo" placeholder="Version Info" />
                        </div>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Version</span>
                            <InputText v-model="setupCopyright.version" placeholder="Version" />
                        </div>
                    </div>
                    <Divider />
                </div>
                <div class="flex justify-content-between align-items-center pt-3 mt-auto">
                    <small class="muted-text">Speichern nicht vergessen!</small>
                </div>
            </div>
        </div>
    </div>
        
        <div class="flex justify-content-end">
            <div class="flex align-items-center justify-content-center m-2">
                <Button label="Speichern" @click="saveConfig" />
            </div>
        </div>
    </div>
</template>

<script>
import SocketioService from '@/services/socketioService';
import { mapGetters } from 'vuex';

export default {
    name: 'configSys',
    data() {
        return {
            products: null,
            loading: false,
            setupApp: {
                standbyTimeout: 999,
            },
            setupClient: {},
            setupLocation: {},
            setupSystem: {},
            setupCopyright: {},
            setupInstallationId: null,
        };
    },
    computed: {
        ...mapGetters({
            editMode: 'getEditMode',
            isMaster: 'auth/isMaster',
        }),
    },
    created() {},
    mounted() {
        this.init();
    },
    beforeUnmount() {
        this.products = null;
        this.loading = false;
        this.setupApp = {
            standbyTimeout: 999,
        };
        this.setupInstallationId = null;
        this.setupClient = {};
        this.setupLocation = {};
        this.setupSystem = {};
    },
    methods: {
        async init() {
            this.loading = true;
            await this.getParameter();
            this.loading = false;
        },
        async getParameter() {
            await this.getSystemInstallation();
            await this.getSystemApp();
            await this.getSystemCopyright();
        },
        async getSystemInstallation() {
            await SocketioService.getSystemInstallation((err, response) => {
                if (!err && response !== null) {
                    this.setupInstallationId = response._id;
                    this.setupClient = response.client;
                    this.setupLocation = response.location;
                    this.setupSystem = response.system;
                }
            });
        },
        async getSystemApp() {
            await SocketioService.getSystemApp((err, response) => {
                if (!err && response !== null) {
                    this.setupApp = response;
                }
            });
        },
        async getSystemCopyright() {
            await SocketioService.getSystemCopyright((err, response) => {
                if (!err && response !== null) {
                    this.setupCopyright = response;
                }
            });
        },
        toastMongoResponse(title, response) {
            if (response.acknowledged && response.matchedCount > 0 && response.upsertedCount === 0 && response.modifiedCount === 0) {
                if (title !== null) {
                    this.$toast.add({
                        severity: 'info',
                        summary: 'Keine Änderungen',
                        detail: title + ' modifiziert: ' + response.modifiedCount + ' , keine Änderung notwendig ',
                        life: 3000,
                    });
                }
                return 0;
                // document updated
            } else if (response.acknowledged && response.matchedCount > 0 && response.upsertedCount === 0 && response.modifiedCount > 0) {
                if (title !== null) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Speichern erfolgreich',
                        detail: title + ' modifiziert: ' + response.modifiedCount + ' , alle Änderungen wurden gespeichert ',
                        life: 3000,
                    });
                }
                return 1;
                // document deleted
            } else if (response.acknowledged && response.deletedCount > 0) {
                if (title !== null) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Löschen erfolgreich',
                        detail: title +  ' ' + response.deletedCount + ' Einträge erfolgreich gelöscht',
                        life: 3000,
                    });
                }
                return 2;
                // document created
            } else if (response.acknowledged && response.insertedId !== null) {
                if (title !== null) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Konfiguration erstellt',
                        detail: title + ' Konfiguration [' + response.insertedId.toString() + '] erfolgreich erstellt',
                        life: 3000,
                    });
                }
                return 3;
            } else {
                if (title !== null) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Speichern fehlgeschlagen',
                        detail: title + ' modifiziert: ' + response.modifiedCount + ' , Erstellt: ' + response.upsertedCount + ' [' + response.upsertedId + ']',
                        life: 3000,
                    });
                }
                return -1;
            }
        },
        async saveInstallation() {
            const returnObj = {
                _id: this.setupInstallationId,
                client: { ...this.setupClient },
                location: { ...this.setupLocation },
                system: { ...this.setupSystem }
            }
            await SocketioService.setSystemInstallation(returnObj, (err, response) => {
                if (!err && response !== null) {
                    this.toastMongoResponse("Anlagenkonfiguration", response);
                }
            });
        },
        async saveApp() {
            await SocketioService.setSystemApp(this.setupApp, (err, response) => {
                if (!err && response !== null) {
                    this.toastMongoResponse("App-Einstellungen", response);
                }
            });
        },
        async saveCopyright() {
            let payload = {};
            payload._id = this.setupCopyright._id;
            payload.payload = { ...this.setupCopyright };
            delete payload.payload._id;
            delete payload.payload.key;
            
            await SocketioService.setSystemCopyright(payload, (err, response) => {
                if (!err && response !== null) {
                    this.toastMongoResponse("Admin-Einstellungen", response);
                }
            });
        },
        async saveConfig() {
            this.loading = true;
            await this.saveInstallation();
            await this.saveApp();
            await this.saveCopyright();
            this.loading = false;
            // this.toastMongoResponse("APP-Config", response);
        },
        restartOpcUaService() {
            // SocketioService.setServerData('opc', 'restart', null, (err, response) => {
            //     if (!err) {
            //         console.log(response);
            //     } else {
            //         console.log(err);
            //     }
            // });
        },
        startOpcUaService() {
            // SocketioService.setServerData('opc', 'register', null, (err, response) => {
            //     if (!err) {
            //         console.log(response);
            //     } else {
            //         console.log(err);
            //     }
            // });
        },
        stopOpcUaService() {
            // SocketioService.setServerData('opc', 'unregister', null, (err, response) => {
            //     if (!err) {
            //         console.log(response);
            //     } else {
            //         console.log(err);
            //     }
            // });
        },
        refreshOpcUaStatusIo() {
            //this.$store.dispatch('loadStatusIo');
        },
    },
};
</script>

<style lang="scss" scoped>
.p-inputgroup-addon {
    width: 200px;
}
</style>